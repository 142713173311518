import decode from "../../decoder/v1";
import PixelChain from "./abi";
import { CONTRACT_ADDRESSES } from "./constants";

const retrieveToken = async (web3, tokenId) => {
  const networkId = await web3.eth.net.getId();
  const contract = new web3.eth.Contract(PixelChain.abi, CONTRACT_ADDRESSES[networkId]);

  return contract.methods
    .retrieve(tokenId)
    .call()
    .then((response) => {
      const { palette, grid, rawPixels, rawPalette } = decode(response[1], response[2]);
      return { palette, grid, rawPixels, rawPalette, name: response[0], author: response[3] };
    });
};

export default { retrieveToken };
