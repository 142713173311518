import styled from "styled-components";

const Tag = styled.span`
  padding: 5px;
  font-size: 12px;
  background: "black";
  color: white;
  border: 1px solid grey;
  border-radius: 5px;
`;

export default Tag;
