import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./home.css";

const TAB_LATEST = 1;
const TAB_LOWEST_ID = 2;
const TAB_FEATURED = 3;

class Home extends Component {
  state = {
    isLoading: true,
    currentTab: TAB_LATEST,
    pixelChains: [],
  };

  componentDidMount() {
    this.fetchPXC();
  }

  onChangeTab = (tab) => {
    this.setState({ currentTab: tab }, this.fetchPXC);
  };

  fetchPXC = () => {
    this.setState({ isLoading: true });
    let type = "latest";

    if (this.state.currentTab === TAB_LOWEST_ID) {
      type = "earliest";
    }

    if (this.state.currentTab === TAB_FEATURED) {
      type = "featured";
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/pixelchains/${type}`, {
        params: {
          limit: 45,
        },
      })
      .then(({ data }) => {
        this.setState({ pixelChains: data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { currentTab, isLoading } = this.state;

    return (
      <div className="home">
        <div className="banner-area">
          <div className="worp-4k">
            <div className="shap1">
              <img src="/images/shap-1.png" alt="" />
            </div>
            <div className="container-warp">
              <div className="banner-content-area">
                <div className="banner-content">
                  <h6>CREATE - TOKENIZE - SALE</h6>
                  <h1>Pixel Art That Lives Forever</h1>
                  <Link to="/editor" className="theme-btn">
                    Start creating
                  </Link>
                </div>
                <div className="banner-img">
                  <img src="/images/banner-2.png" alt="" className="md-none" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="filter-area">
          <div className="worp-4k">
            <div className="shap1">
              <img src="/images/shap-1.png" alt="" />
            </div>
            <div className="shap2">
              <img src="/images/shap-2.png" alt="" />
            </div>
            <div className="container-warp">
              <div className="tab">
                <button
                  className={`tablinks ${currentTab === TAB_LATEST ? "active" : null}`}
                  onClick={() => this.onChangeTab(TAB_LATEST)}
                >
                  Latest
                </button>
                <button
                  className={`tablinks ${currentTab === TAB_LOWEST_ID ? "active" : null}`}
                  onClick={() => this.onChangeTab(TAB_LOWEST_ID)}
                >
                  Lowest ID
                </button>
                <button
                  className={`tablinks ${currentTab === TAB_FEATURED ? "active" : null}`}
                  onClick={() => this.onChangeTab(TAB_FEATURED)}
                >
                  {" "}
                  Featured
                </button>
              </div>

              <div className="tabcontent">
                {!isLoading ? (
                  <div className="ds-grid">
                    {this.state.pixelChains.map(({ token_id, image }) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://opensea.io/assets/0x9e1f3e8db4d1119894624632499eaed1e56d2b1d/${token_id}`}
                      >
                        <img className="pixelchain" alt={`token_id_${token_id}`} key={token_id} src={image} />
                      </a>
                    ))}
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/collection/pixelchain"
                className="theme-btn"
              >
                View ALL
              </a>
            </div>
          </div>
        </section>

        <div className="graphic-arer">
          <div className="worp-4k">
            <div className="shap2">
              <img src="/images/shap-2.png" alt="" />
            </div>
            <div className="container-warp">
              <div className="graphic-items">
                <div className="graphic-item">
                  <div className="graphic-img">
                    <img src="/images/graphic-1.png" alt="" />
                  </div>
                  <div className="graphic-content">
                    <p>Draw Pixel Art</p>
                  </div>
                </div>
                <div className="graphic-item grit-center">
                  <div className="graphic-img">
                    <img src="/images/graphic-2.png" alt="" />
                  </div>
                  <div className="graphic-content">
                    <p>
                      Mint Your Artwork Fully <br />
                      On Chain (ETH)
                    </p>
                  </div>
                </div>
                <div className="graphic-item">
                  <div className="graphic-img">
                    <img src="/images/graphic-3.png" alt="" />
                  </div>
                  <div className="graphic-content">
                    <p>Sell Your Art In Opensea</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="gr-all">
              <p>Your Creation Is 100% Stored On Chain</p>
              <Link to="/editor" className="theme-btn">
                Start Drawing
              </Link>
            </div>
          </div>
        </div>

        <div className="store-work-arer">
          <div className="worp-4k">
            <div className="shap1">
              <img src="/images/shap-1.png" alt="" />
            </div>
            <div className="shap2">
              <img src="/images/shap-2.png" alt="" />
            </div>
            <div className="container-warp">
              <div className="store-work-flex">
                <div className="st-work-img">
                  <img src="/images/store-1.png" alt="" />
                </div>
                <div className="st-work-content">
                  <h2>Why we store the Art on Chain ?</h2>
                  <p>
                    Today most NFTs and CryptoArtworks share the same problem, the images are stored off chain. If the
                    project where the data is stored dies the graphic information of the artwork will be most probably
                    lost.
                  </p>
                  <br />
                  <p>
                    Storing all the artwork information and metadata on chain solves this problem. <br />
                    Now you can create and store art 100% on chain that will live forever.
                  </p>
                </div>
              </div>

              <div className="store-work-flex">
                <div className="st-work-content">
                  <h2>How it works?</h2>
                  <p>
                    When ever a PixelChain gets minted our innovator smart contract will encode all the image data,
                    compress it and send it to the blockchain, where it gets stored together with its name and author
                    information.
                  </p>
                  <br />
                  <p>
                    Later on this data can be always accessible directly from the blockchain, <br />
                    decompressed and decoded by our
                    <a
                      className="open-source-decoder"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://github.com/PixelChainDapp/PixelChain-GoLang-Decoder"
                    >
                      open source decoder
                    </a>
                    in order to rebuild the original image that was created by the artist.
                  </p>
                  <br />
                </div>
                <div className="st-work-img img-order">
                  <img src="/images/work-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="roadmap-arer">
          <div className="worp-4k">
            <h2>Pixelchain Roadmap</h2>
            <div className="container-warp">
              <div className="roadmap-flex">
                <Link to="#" className="roadmap-items btm-30">
                  <p>PXC</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>PXC Max</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>Animations</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>Voxels</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>Midi</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>SocialChain</p>
                  <span></span>
                </Link>
                <Link to="#" className="roadmap-items btm-30">
                  <p>LandChain</p>
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="beginning-arer">
          <div className="worp-4k">
            <div className="shap2">
              <img src="/images/shap-2.png" alt="" />
            </div>
            <div className="container-warp">
              <div className="beginning-content">
                <h2>This Is Just The Beginning... </h2>
                <p>
                  This is our MVP solution for storing Art 100% On-chain. We will apply the same principle <br />
                  to store other kinds of artwork like Music and Voxels
                </p>
                <Link to="/editor" className="theme-btn">
                  Start creating
                </Link>
              </div>
            </div>
          </div>
        </div>

        <footer className="beginning-arer">
          <div className="worp-4k">
            <div className="container-warp">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/editor">EDITOR </Link>
                  </li>
                  <li>
                    <Link to="/market">MARKET</Link>
                  </li>
                  <li>
                    <Link to="/decoder">decoder</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                  </li>
                  <li>
                    <Link to="/impressum">IMPRESSUM</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">TERMS AND CONDITIONS</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-bottom">
                <div className="social-flaex">
                  <p>Pixelchain.Art All Rights Reserverd.</p>
                  <div className="social-link">
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/PixelChain.art/">
                      <img src="/images/facebook.png" alt="" />
                    </a>
                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/Pixelchain_Dapp">
                      <img src="/images/twitter.png" alt="" />
                    </a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pixelchain.art">
                      <img src="/images/instagram.png" alt="" />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://github.com/PixelChainDapp/PixelChain-GoLang-Decoder"
                    >
                      <img src="/images/github.png" alt="" />
                    </a>
                  </div>
                </div>
                <p>Powered By CryptoMotors UG</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Home;
