import React, { Component } from "react";
import axios from "axios";

import "./Profile.css";

const TAB_MY_CREATIONS = 1;
const TAB_MY_COLLECTION = 2;

class Decoder extends Component {
  state = {
    isLoading: true,
    createdPXC: [],
    ownedPXC: [],
    selectedTab: TAB_MY_CREATIONS,
  };

  componentDidMount() {
    this.fetchCreatedPXC();
  }

  fetchCreatedPXC = () => {
    const { walletAddress } = this.props;
    if (walletAddress === null) {
      return;
    }

    this.setState({ isLoading: true });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/pixelchains/${walletAddress}/creator`)
      .then(({ data }) => {
        this.setState({ createdPXC: data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchOwnedPXC = () => {
    const { walletAddress } = this.props;
    if (walletAddress === null) {
      return;
    }
    this.setState({ isLoading: true });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/pixelchains/${walletAddress}/owner`)
      .then(({ data }) => {
        this.setState({ ownedPXC: data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickMyCreations = () => {
    this.fetchCreatedPXC();
    this.setState({ selectedTab: TAB_MY_CREATIONS });
  };

  onClickMyCollection = () => {
    this.fetchOwnedPXC();
    this.setState({ selectedTab: TAB_MY_COLLECTION });
  };

  render() {
    const { connectWallet, disconnectWallet, walletAddress } = this.props;
    const { isLoading, selectedTab } = this.state;

    if (walletAddress === null) {
      return (
        <div className="profile">
          <button className="linkButton" onClick={connectWallet}>
            Connect Wallet
          </button>
        </div>
      );
    }

    return (
      <div className="profile">
        <div className="container">
          <div className="userData">
            <span className="address">{walletAddress}</span>
            <button className="linkButton" onClick={disconnectWallet}>
              Disconnect Wallet
            </button>
          </div>
          <div className="headerGallery">
            <button
              className={`linkButton ${selectedTab === TAB_MY_CREATIONS ? "active" : ""}`}
              onClick={this.onClickMyCreations}
            >
              My Creations
            </button>
            {" | "}
            <button
              className={`linkButton ${selectedTab === TAB_MY_COLLECTION ? "active" : ""}`}
              onClick={this.onClickMyCollection}
            >
              My Collection
            </button>
          </div>
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <div className="gallery">
              {selectedTab === TAB_MY_CREATIONS
                ? this.state.createdPXC.map(({ token_id, image }) => (
                    <img alt={`token_id_${token_id}`} key={token_id} className="pixelchain" src={image} />
                  ))
                : this.state.ownedPXC.map(({ token_id, image }) => (
                    <img alt={`token_id_${token_id}`} key={token_id} className="pixelchain" src={image} />
                  ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Decoder;
