import React, { useState, useRef } from "react";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "components/Tabs";
import Logo from "./pixelchain.png";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";

const Header = styled.div`
  background: #1e2132 none repeat scroll 0 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 991px) {
    /* display: none; */
  }
`;

const Title = styled.h2`
  font-size: 2vmax;
  text-align: center;
  color: white;
  text-shadow: 5px 5px 3px #333;

  @media screen and (max-width: 991px) {
    /* display: none; */
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  padding: 20px;
  height: 64px;
`;

const GlowingText = styled.div`
	-webkit-animation: text-shadow-drop-center 0.6s infinite alternate-reverse both;
  animation: text-shadow-drop-center 0.6s infinite alternate-reverse both;

 @-webkit-keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(247, 119, 88, 1);
  }
}
@keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(247, 119, 88, 1);
  }
}
`;

const HeaderComponent = ({ history, location, connectWallet, walletAddress }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  return (
    <Header>
      <TitleContainer onClick={() => history.push("/")}>
        <Image src={Logo} />
        <Title>PixelChain</Title>
      </TitleContainer>
      <Media query="(max-width: 991px)">
        {(matches) =>
          matches ? (
            <div ref={node}>
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} walletAddress={walletAddress} connectWallet={connectWallet} />
            </div>
          ) : (
            <>
              <Tabs>
                <Tab onClick={() => window.location.href = "https://pxc256.art"}>
                  <GlowingText>PXC-256</GlowingText>
                </Tab>
                <Tab onClick={() => history.push("/editor")} selected={location.pathname === "/editor"}>
                  Editor
                </Tab>
                <Tab onClick={() => history.push("/marketplace")} selected={location.pathname === "/marketplace"}>
                  Market
                </Tab>
                <Tab onClick={() => history.push("/decoder")} selected={location.pathname === "/decoder"}>
                  Decoder
                </Tab>
                {walletAddress === null ? (
                  <Tab onClick={connectWallet}>Connect</Tab>
                ) : (
                  <Tab onClick={() => history.push("/profile")}>Profile</Tab>
                )}
              </Tabs>
            </>
          )
        }
      </Media>
    </Header>
  );
};

export default withRouter(HeaderComponent);
