import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  margin-right: 1rem;
`;

const Tabs = ({ children }) => <Container>{children}</Container>;
export default Tabs;
