import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: ${({ selected }) => (selected ? "#ffffff" : "#7d8da9")};
  padding: 10px;
  cursor: pointer;
  font-size: 1.6rem;
`;

const Tab = ({ onClick, selected, children }) => (
  <Container onClick={onClick} selected={selected}>
    {children}
  </Container>
);

export default Tab;
