import React from "react";
import styled from "styled-components";
import { Switch, Redirect, Route } from "react-router";
import { withRouter } from "react-router-dom";
import Home from "./containers/Home";
import Editor from "./containers/Editor";
import Marketplace from "./containers/Marketplace";
import Decoder from "./containers/Decoder";
import Profile from "./containers/Profile";
import Header from "./components/Header";
import Terms from "./components/Terms";
import Analytics from "react-router-ga";
import Privacy from "./components/Privacy/Privacy";
import Impressum from "./components/Impressum/Impressum";
import CacheBuster from "./components/CacheBuster/CacheBuster";
import withPixelChainContract from "containers/PixelChainContractWrapper";

const Container = styled.div`
  min-height: 100vh;
  background: #2b2e43 none repeat scroll 0 0;
  display: flex;
  flex-direction: column;
`;

const debug = window.location.hostname !== "pixelchain.cryptomotors.io";


const App = ({ connectWallet, disconnectWallet, walletAddress }) => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <Container>
            <Analytics id={debug ? "no-code" : "UA-124177179-2"} debug={debug}>
              <Header connectWallet={connectWallet} disconnectWallet={disconnectWallet} walletAddress={walletAddress} />
              <Switch>
                <Route path="/editor" component={Editor} />
                <Route path="/marketplace" component={Marketplace} />
                <Route path="/decoder" component={Decoder} />
                <Route
                  path="/profile"
                  component={() => (
                    <Profile
                      connectWallet={connectWallet}
                      disconnectWallet={disconnectWallet}
                      walletAddress={walletAddress}
                    />
                  )}
                />
                <Route path="/terms-and-conditions" component={Terms} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route path="/impressum" component={Impressum} />
                <Route path="/" component={Home} />
                <Redirect to="/editor" />
              </Switch>
            </Analytics>
          </Container>
        );
      }}
    </CacheBuster>
  );
};

export default withRouter(withPixelChainContract(App));
