import React from "react";
import styled from "styled-components";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Title = styled.h1`
  font-size: 2vmax;
  text-align: center;
  color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #212439 none repeat scroll 0 0;
  padding: 20px;
  align-items: center;
  color: white;

  @media screen and (max-width: 991px) {
    /* display: none; */
  }
  margin: 0 1rem;
`;

const Modal = ({ title, children }) => (
  <Backdrop>
    <Content>
      <Title>{title}</Title>
      {children}
    </Content>
  </Backdrop>
);

export default Modal;
