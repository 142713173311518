import React from "react";
import styled from "styled-components";

const PaletteButton = styled.div`
  width: 42px;
  height: 42px;
  margin-left: 1rem;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
`;

const PaletteQuantityButton = ({ img, onClick }) => {
  return (
    <PaletteButton onClick={() => onClick()}>
      <Image src={img} alt="Pallette Button" />
    </PaletteButton>
  );
};

export default PaletteQuantityButton;
