import React from "react";
import styled from "styled-components";

//  import Loading from 'components/Loading';

const Container = styled.div`
  background-color: #f0efef;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

const IframeContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Marketplace extends React.Component {
  state = {
    loading: true,
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <Container>
        <IframeContainer>
          {this.state.loading && <div>Loading</div>}
          <iframe
            src="https://opensea.io/assets/pixelchain"
            width="100%"
            height="100%"
            frameBorder="0"
            title="market"
            onLoad={this.hideLoading}
            style={{ display: this.state.loading ? "none" : "flex" }}
            allowFullScreen
          />
        </IframeContainer>
      </Container>
    );
  }
}

export default Marketplace;
