import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 40rem;

  @media screen and (max-width: 760px) {
    min-width: 20rem;
  }

  @media screen and (max-width: 428px) {
    min-width: 15rem;
  }
`;

const Color = styled.div`
  background: ${({ color }) => color};
  height: 40px;
  flex-basis: 6.25%;

  @media screen and (max-width: 760px) {
    flex-basis: 12.5%;
  }

  @media screen and (max-width: 428px) {
    height: 30px;
    flex-basis: 12.5%;
  }
`;

const PalettePreview = ({ palette, style }) => (
  <Container style={style}>
    {palette.map((color, index) => (
      <Color color={color} key={index} />
    ))}
  </Container>
);

export default PalettePreview;
