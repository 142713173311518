import React from "react";
import styled from "styled-components";
import Tools from "./Tools";

const Container = styled.div`
  padding: 20px;

  @media screen and (max-width: 991px) {
    padding: 20px 0 20px 0;
    width: 100%;
    text-align: center;
  }
`;

const Canvas = styled.canvas`
  border: 0.5px solid black;
`;

class CanvasEditorGrid extends React.Component {
  mouseDown = false;
  shiftKey = false;
  ctrlKey = false;
  grid = null;

  static defaultProps = {
    pixelSize: 20,
    width: 32,
    height: 32,
  };

  componentDidUpdate() {
    this.canvasContext = this.canvas.getContext("2d");
    this.grid = JSON.parse(JSON.stringify(this.props.grid));
    this.redraw();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
    //Mobile events
    document.addEventListener(
      "touchstart",
      (e) => {
        this.onTouchStart();
        if (e.target === this.canvas) {
          e.preventDefault();
        }
      },
      { capture: false, passive: false }
    );
    document.addEventListener(
      "touchend",
      (e) => {
        this.onTouchEnd();
        if (e.target === this.canvas) {
          e.preventDefault();
        }
      },
      { capture: false, passive: false }
    );
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
    document.removeEventListener("touchstart", this.onTouchStart);
    document.removeEventListener("touchend", this.onTouchEnd);
  }

  paintPixel = (y, x) => {
    this.canvasContext.fillStyle = this.props.palette[this.props.color.id];
    this.canvasContext.fillRect(
      x * this.getPixelSize(),
      y * this.getPixelSize(),
      this.getPixelSize(),
      this.getPixelSize()
    );
    this.grid[y][x] = this.props.color.id;
  };

  paintMirroredPixel = (y, x) => {
    const mirrorX = this.getPixelWidthCount() - x - 1;
    const mirrorY = this.getPixelHeightCount() - y - 1;
    this.canvasContext.fillStyle = this.props.palette[this.props.color.id];

    this.canvasContext.fillRect(
      x * this.getPixelSize(),
      y * this.getPixelSize(),
      this.getPixelSize(),
      this.getPixelSize()
    );
    this.grid[y][x] = this.props.color.id;

    if (this.ctrlKey) {
      this.canvasContext.fillRect(
        x * this.getPixelSize(),
        mirrorY * this.getPixelSize(),
        this.getPixelSize(),
        this.getPixelSize()
      );
      this.grid[mirrorY][x] = this.props.color.id;
    } else if (this.shiftKey) {
      this.canvasContext.fillRect(
        mirrorX * this.getPixelSize(),
        mirrorY * this.getPixelSize(),
        this.getPixelSize(),
        this.getPixelSize()
      );
      this.canvasContext.fillRect(
        x * this.getPixelSize(),
        mirrorY * this.getPixelSize(),
        this.getPixelSize(),
        this.getPixelSize()
      );
      this.canvasContext.fillRect(
        mirrorX * this.getPixelSize(),
        y * this.getPixelSize(),
        this.getPixelSize(),
        this.getPixelSize()
      );
      this.grid[mirrorY][x] = this.props.color.id;
      this.grid[mirrorY][mirrorX] = this.props.color.id;
      this.grid[y][mirrorX] = this.props.color.id;
    } else {
      this.canvasContext.fillRect(
        mirrorX * this.getPixelSize(),
        y * this.getPixelSize(),
        this.getPixelSize(),
        this.getPixelSize()
      );
      this.grid[y][mirrorX] = this.props.color.id;
    }
  };

  paintWithBucket = (y, x, startColor, color) => {
    if (
      this.grid[y] === undefined ||
      this.grid[y][x] === undefined ||
      this.grid[y][x] !== startColor ||
      this.grid[y][x] === color
    ) {
      return;
    }
    this.paintPixel(y, x);
    this.paintWithBucket(y + 1, x, startColor, color);
    this.paintWithBucket(y - 1, x, startColor, color);
    this.paintWithBucket(y, x + 1, startColor, color);
    this.paintWithBucket(y, x - 1, startColor, color);
  };

  onPixelHover = (isMobile, e) => {
    const hoveredPixel = this.getMousePosition(isMobile, e);
    let changed = false;

    if (this.mouseDown && this.props.tool === Tools.PENCIL) {
      this.paintPixel(hoveredPixel.y, hoveredPixel.x);
      changed = true;
    } else if (this.mouseDown && this.props.tool === Tools.MIRROR) {
      this.paintMirroredPixel(hoveredPixel.y, hoveredPixel.x);
      changed = true;
    } else if (this.props.tool === 1 && this.startLine) {
      // this.drawLine(this.startPoint.y, this.startPoint.x, y, x);
    }

    if (changed) {
      this.props.onChange(this.grid);
    }

    this.drawHover(hoveredPixel.y, hoveredPixel.x);
  };

  drawHover = (y, x) => {
    this.redraw();
    this.canvasContext.globalAlpha = 0.2;
    this.canvasContext.fillStyle = "black";
    this.canvasContext.fillRect(
      x * this.getPixelSize(),
      y * this.getPixelSize(),
      this.getPixelSize(),
      this.getPixelSize()
    );
    this.canvasContext.globalAlpha = 1;
  };

  onPixelClick = (isMobile, e) => {
    const clickedPixel = this.getMousePosition(isMobile, e);
    if (this.props.tool === Tools.PENCIL) {
      this.paintPixel(clickedPixel.y, clickedPixel.x);
    } else if (this.props.tool === Tools.MIRROR) {
      this.paintMirroredPixel(clickedPixel.y, clickedPixel.x);
    } else if (this.props.tool === Tools.LINE) {
    } else if (this.props.tool === Tools.BUCKET) {
      this.paintWithBucket(
        clickedPixel.y,
        clickedPixel.x,
        this.grid[clickedPixel.y][clickedPixel.x],
        this.props.color.id
      );
    }

    this.props.onChange(this.grid);
  };

  getMousePosition = (isMobile, event) => {
    const rect = this.canvas.getBoundingClientRect();
    const x = Math.floor(((isMobile ? event.touches[0].clientX : event.clientX) - rect.left) / this.getPixelSize());
    const y = Math.floor(((isMobile ? event.touches[0].clientY : event.clientY) - rect.top) / this.getPixelSize());

    let boundedX = x;
    if (boundedX < 0) {
      boundedX = 0;
    } else if (boundedX > this.getPixelWidthCount() - 1) {
      boundedX = this.getPixelWidthCount() - 1;
    }

    let boundedY = y;
    if (boundedY < 0) {
      boundedY = 0;
    } else if (boundedY > this.getPixelHeightCount() - 1) {
      boundedY = this.getPixelHeightCount() - 1;
    }

    return { x: boundedX, y: boundedY };
  };

  drawGrid = () => {
    this.canvasContext.strokeStyle = "black";
    this.canvasContext.lineWidth = 0.1;
    this.canvasContext.beginPath();

    for (let row = 0; row < this.getPixelHeightCount(); row++) {
      this.canvasContext.moveTo(row * this.getPixelSize(), 0);
      this.canvasContext.lineTo(row * this.getPixelSize(), this.getCanvasWidth());
    }

    for (let column = 0; column < this.getPixelWidthCount(); column++) {
      this.canvasContext.moveTo(0, column * this.getPixelSize());
      this.canvasContext.lineTo(this.getCanvasHeight(), column * this.getPixelSize());
    }

    this.canvasContext.stroke();
    this.canvasContext.closePath();
  };

  onMouseDown = () => {
    this.mouseDown = true;
  };

  onMouseUp = () => {
    this.mouseDown = false;
  };

  onKeyDown = (e) => {
    this.shiftKey = e.shiftKey;
    this.ctrlKey = e.ctrlKey;
  };

  onKeyUp = () => {
    this.shiftKey = false;
    this.ctrlKey = false;
  };

  onTouchStart = () => {
    this.mouseDown = true;
  };

  onTouchEnd = () => {
    this.mouseDown = false;
  };
  // drawCircle = (x, y, radius) => {

  // }

  redraw = () => {
    this.canvasContext.clearRect(0, 0, this.getCanvasWidth(), this.getCanvasHeight());

    for (let row = 0; row < this.grid.length; row++) {
      for (let column = 0; column < this.grid[row].length; column++) {
        this.canvasContext.fillStyle = this.props.palette[this.grid[row][column]];
        this.canvasContext.fillRect(
          column * this.getPixelSize(),
          row * this.getPixelSize(),
          this.getPixelSize(),
          this.getPixelSize()
        );
      }
    }

    if (this.props.showGrid) {
      this.drawGrid();
    }
  };

  getPixelSize = () => this.props.pixelSize;

  getPixelWidthCount = () => this.props.width;

  getPixelHeightCount = () => this.props.height;

  getCanvasWidth = () => this.getPixelWidthCount() * this.getPixelSize();

  getCanvasHeight = () => this.getPixelHeightCount() * this.getPixelSize();

  render() {
    return (
      <Container draggable="false">
        <Canvas
          draggable="false"
          width={this.getCanvasWidth()}
          height={this.getCanvasHeight()}
          ref={(ref) => (this.canvas = ref)}
          onClick={(e) => this.onPixelClick(false, e)}
          onMouseMove={(e) => this.onPixelHover(false, e)}
          onTouchStart={(e) => this.onPixelClick(true, e)}
          onTouchMove={(e) => this.onPixelHover(true, e)}
        />
      </Container>
    );
  }
}

export default CanvasEditorGrid;
