import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import queryString from "query-string";
import PalettePreview from "../../components/PalettePreview";
import withPixelChainContract from "containers/PixelChainContractWrapper";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #2b2e43 none repeat scroll 0 0;
  margin-top: 5rem;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    margin-top: 2.5rem;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (max-width: 991px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 428px) {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
`;

const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 30px;
  background: #7d8da9;
  color: #212439;

  :active {
    color: white;
  }

  :hover {
    color: #212439;
  }

  @media screen and (max-width: 991px) {
    font-size: 5vw;
    margin: 10px;
  }

  @media screen and (max-width: 428px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8vw;
    width: 8vw;
  }

  ${({ selected }) => (selected ? "color: white;" : "")}
`;

const Input = styled.input`
  width: 300px;
  font-size: 1.3em;
  padding: 0.5rem;
  border-radius: 3px;
  margin-right: 10px;

  ::placeholder {
    color: grey;
  }

  @media screen and (max-width: 428px) {
    width: 250px;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  overflow-wrap: break-word;
`;

const TokenFieldLabel = styled.div`
  margin-bottom: 10px;
  font-size: 2em;
  color: grey;
`;

const TokenField = styled.div`
  color: grey;
  margin-bottom: 10px;
`;

const Canvas = styled.canvas`
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
`;

const defaultScaleSize = 256;

class Decoder extends React.Component {
  state = {
    author: null,
    name: null,
    rawPalette: null,
    rawPixels: null,
    pixels: [],
    palette: [],
    tokenId: "",
    scaleSize: defaultScaleSize,
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.id || values.version) {
      this.setState(
        { tokenId: values.id, version: values.version ? values.version : 2 },
        this.loadPixelChainFromEthereum
      );
    }
  }

  loadPixelChainFromEthereum = async () => {
    const pixelChain = await this.props.retrieveToken(this.state.tokenId, parseInt(this.state.version, 10));

    if (!pixelChain) {
      return;
    }

    this.setState(
      {
        author: pixelChain.author,
        name: pixelChain.name,
        rawPalette: pixelChain.rawPalette,
        rawPixels: pixelChain.rawPixels,
        grid: pixelChain.grid,
        palette: pixelChain.palette,
      },
      this.drawPixelChain
    );
  };

  drawPixelChain = () => {
    const grid = this.state.grid;
    const palette = this.state.palette;

    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.oImageSmoothingEnabled = false;
    const scaleSize = Math.ceil(defaultScaleSize / grid.length) * grid.length;
    ctx.canvas.width = grid.length;
    ctx.canvas.height = grid.length;

    this.setState({ scaleSize }, () => {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, grid.length, grid.length);
      for (let row = 0; row < grid.length; row++) {
        for (let column = 0; column < grid[row].length; column++) {
          ctx.fillStyle = palette[grid[row][column]];
          ctx.fillRect(column, row, 1, 1);
        }
      }
    });
  };

  onTokenIdChange = (e) => this.setState({ tokenId: e.target.value });

  downloadPalette = () => {
    const element = document.createElement("a");
    const paletteData = JSON.stringify(this.state.palette);
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(paletteData));
    element.setAttribute("download", `${this.state.tokenId}_palette.json`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    return (
      <Container>
        <RowContainer>
          <Input placeholder="token ID" value={this.state.tokenId} onChange={this.onTokenIdChange} type="text" />
          <Button style={{ height: "49px", width: "50px" }} onClick={this.loadPixelChainFromEthereum}>
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </Button>
        </RowContainer>
        <RowContainer>
          <Canvas
            id="canvas"
            style={{
              width: this.state.scaleSize,
              height: this.state.scaleSize,
              imageRendering: "pixelated",
            }}
          ></Canvas>
        </RowContainer>
        {this.state.palette.length > 0 && (
          <RowContainer>
            <PalettePreview palette={this.state.palette} />
            <Button
              style={{
                background: "none",
                color: "#7d8da9",
                paddingLeft: "10px",
              }}
              onClick={this.downloadPalette}
            >
              <FontAwesomeIcon icon={faFileDownload} size="lg" />
            </Button>
          </RowContainer>
        )}
        {this.state.rawPixels && (
          <TokenInfo>
            <TokenFieldLabel>Name</TokenFieldLabel>
            <TokenField>{this.state.name}</TokenField>
            <TokenFieldLabel>Author</TokenFieldLabel>
            <TokenField>{this.state.author}</TokenField>
            <TokenFieldLabel>Palette Data</TokenFieldLabel>
            <TokenField
              style={{
                overflow: "hidden",
                textTransform: "uppercase",
              }}
            >
              {this.state.rawPalette}
            </TokenField>
            <TokenFieldLabel>Pixel Data</TokenFieldLabel>
            <TokenField
              style={{
                overflow: "hidden",
                textTransform: "uppercase",
              }}
            >
              {this.state.rawPixels}
            </TokenField>
          </TokenInfo>
        )}
      </Container>
    );
  }
}

export default withPixelChainContract(Decoder);
