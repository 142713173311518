import React from "react";
import Tag from "../../components/Tag";

export const Tooltips = {
  PENCIL: (
    <p>
      Pencil: <Tag>B</Tag>
    </p>
  ),
  BUCKET: (
    <p>
      Bucket: <Tag>G</Tag>
    </p>
  ),
  UNDO: (
    <p>
      Undo: <Tag>Ctrl</Tag> + <Tag>Z</Tag>
    </p>
  ),
  GRID: <p>Toggle grid</p>,
  PALETTE_UPLOAD: <p>Upload a palette file</p>,
  PALETTE: (
    <p>
      Colors: <Tag>1</Tag>, <Tag>2</Tag>, <Tag>...</Tag>
    </p>
  ),
  CLEAR: <p>Clears the session workspace</p>,
  SAVE: <p>Save on Ethereum</p>,
  MIRROR: (
    <div>
      <p>
        Mirror Pencil: <Tag>V</Tag>
      </p>
      <p>
        <Tag>CTRL</Tag> Vertical axis
      </p>
      <p>
        <Tag>SHIFT</Tag> Both axis
      </p>
    </div>
  ),
  IMPORT: <p>Import from Ethereum</p>,
};
