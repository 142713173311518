import React from "react";
import styled from "styled-components";

const PreviewsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewContainer = styled.div`
  align-items: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: flex-end;

  p {
    justify-self: flex-start;
    margin: 10px;
    font-size: 2em;
    color: white;
    text-shadow: 5px 5px 3px #333;
  }
`;

export const Preview = ({ title, children }) => (
  <PreviewContainer>
    {children}
    <p>{title}</p>
  </PreviewContainer>
);

export const Previews = ({ children, className }) => (
  <PreviewsContainer className={className}>{children}</PreviewsContainer>
);
