import decode from "../../decoder/v2";
import encode from "../../encoder/v2";
import PixelChainMax from "./abi";
import { CONTRACT_ADDRESSES } from "./constants";
import { MINT_FEE } from "../../../../utils/constants";

const PIXELCHAIN_MINT_TOPIC = "PixelChainCreated(uint256,bytes,bytes,uint8)";

const retrieveToken = async (web3, tokenId) => {
  const networkId = await web3.eth.net.getId();
  const contract = new web3.eth.Contract(PixelChainMax.abi, CONTRACT_ADDRESSES[networkId]);
  const pixelchainMetadata = await contract.methods.retrieve(tokenId).call();
  const topicHash = web3.utils.sha3(PIXELCHAIN_MINT_TOPIC);

  const [element] = await web3.eth.getPastLogs({
    address: CONTRACT_ADDRESSES[networkId],
    fromBlock: 0,
    toBlock: "latest",
    topics: [topicHash, "0x" + new web3.utils.BN(tokenId).toString(16, 64)],
  });

  if (!element) {
    return;
  }

  const event = web3.eth.abi.decodeLog(
    [
      {
        type: "uint256",
        name: "tokenId",
        indexed: true,
      },
      {
        type: "bytes",
        name: "data",
      },
      {
        type: "bytes",
        name: "palette",
      },
      {
        type: "uint8",
        name: "version",
      },
    ],
    element.data,
    element.topics.slice(1)
  );

  const decodedData = decode(event.data, event.palette);

  return {
    name: pixelchainMetadata.name,
    author: pixelchainMetadata.author,
    palette: decodedData.palette,
    rawPixels: decodedData.rawPixels,
    rawPalette: decodedData.rawPalette,
    grid: decodedData.grid,
  };
};

const createToken = async (web3, name, gridData, paletteData) => {
  const networkId = await web3.eth.net.getId();
  const contract = new web3.eth.Contract(PixelChainMax.abi, CONTRACT_ADDRESSES[networkId]);

  const { data, palette } = encode(gridData, paletteData);
  const accounts = await web3.eth.getAccounts();

  let gasLimit = null;

  try {
    gasLimit = await contract.methods
      .create({
        name: name,
        data,
        palette,
        version: 0,
      })
      .estimateGas({ value: MINT_FEE, from: accounts[0] });
  } catch (error) {}

  return () =>
    contract.methods
      .create({
        name: name,
        data,
        palette,
        version: 0,
      })
      .send({ value: MINT_FEE, from: accounts[0], gasLimit: gasLimit });
};

export default { retrieveToken, createToken };
