import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const Privacy = styled.div`
  background: #1e2132 none repeat scroll 0 0;
  width: 100%;
  color: #FFFFFF
  font-family: 'Roboto', sans-serif;
`;

const Center = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 50rem;
  p > ol > li {
    margin: 2rem 0;
  }
  p > ol > li > ol > li {
    margin: 1rem 0;
  }
  a {
    color: #7d8da9;
  }
`;

const PrivacyComponent = () => (
  <Privacy>
    <Center>
      <h1>Privacy Policy</h1>
      <p>
        <span>Effective date: April 16, 2020</span>
      </p>
      <p>
        CryptoMotors (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the www.pixelchain.art website
        (the&quot;Service&quot;).
      </p>
      <p>
        This page informs you of our policies regarding the collection, use and disclosure of personal data when you use
        our Service and the choices you have associated with that data.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the Service, you agree to the collection and use
        of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in
        this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from www.pixelchain.art
      </p>
      <h3>Information Collection And Use</h3>
      <p>
        The only personal data related to you that we store is your digital wallet address and the digital signature of
        Terms of Use and Privacy Policy. Optionally email and nickname can be provided by the user and stored in the
        database. In most cases refusal to provide the reliable information will lead to an inability to provide
        necessary services to you. Personal data related to you cannot be used without your consent. But we also have
        the right to use the personal data without your consent in order to perform the contract (agreement) with you,
        to fulfill lawful governmental requirements, as well as in other cases expressly permitted by applicable law.
        <br />
        <br />
        We undertake to ensure the safe processing of personal data and avoidance of unauthorized access, deletion or
        modification of such personal data. The above-mentioned measures must comply with the existing level of
        technological development and shall be applied taking into account the degree of harm that may be caused to you
        in case of unauthorized access, deletion or modification of personal data, but in any event, such measures
        should be not lower than the requirements of the applicable legislation.
        <br />
        <br />
        We do not collect any payment-related information about you, except for your publicly available Ethereum wallet
        address. We use your digital wallet address to guarantee your right of ownership of the Pixelchain that you have
        purchased. We do not collect any other personally identifiable information about you unless you contact us
        directly via email, application form or social network. In some case, some other information might be accessible
        to us, including such information which necessary to identify payer in the specific payment system, to solve
        claims of the subject or other entities, some other technical information, including IP and software used for
        access to the websites or online services. Processing of your personal data shall mean any action (operation) or
        a combination of actions (operations) carried out with personal data including collection, recording,
        systematization, accumulation, storage, adjustment (update, change), extraction, use, transfer (distribution,
        assignment, access), depersonalization, blocking, deletion, destruction.
      </p>
      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>
        While using our Service, we may ask you to provide us with certain personally identifiable information that can
        be used to contact or identify you (&quote;Personal Data&quote;). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul>
        <li>Ethereum wallet address</li>
        <li>Country of residence</li>
        <li>Nickname</li>
        <li>Email address</li>
        <li>Cookies and Usage Data</li>
      </ul>
      <h4>Usage Data</h4>
      <p>
        We may also collect information about how the Service is accessed and used (&quote;Usage Data&quote;). This
        Usage Data may include information such as your computer&apos;s Internet Protocol address (e.g. IP address),
        browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the
        time spent on those pages, unique device identifiers and other diagnostic data.
      </p>
      <h4>Tracking &amp; Cookies Data</h4>
      <p>
        We use cookies and similar tracking technologies to track the activity on our Service and hold certain
        information.
      </p>
      <p>
        Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent
        to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags and
        scripts to collect and track information and to improve and analyze our Service.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
        do not accept cookies, you may not be able to use some portions of our Service.
      </p>
      <p>Examples of Cookies we use:</p>
      <ul>
        <li>Session Cookies. &nbsp;We use Session Cookies to operate our Service.</li>
        <li>Preference Cookies. &nbsp;We use Preference Cookies to remember your preferences and various settings.</li>
        <li>Security Cookies. &nbsp;We use Security Cookies for security purposes.</li>
      </ul>
      <h2>Use of Data</h2>
      <p>Pixelchain uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain the Service</li>
        <li>To notify you about changes to our Service</li>
        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
        <li>To provide customer care and support</li>
        <li>To provide analysis or valuable information so that we can improve the Service</li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>
      <h2>Transfer Of Data</h2>
      <p>
        Your information, including Personal Data, may be transferred to — and maintained on — computers located outside
        of your state, province, country or other governmental jurisdiction where the data protection laws may differ
        than those from your jurisdiction.
      </p>
      <p>
        If you are located outside Germany and choose to provide information to us, please note that we transfer the
        data, including Personal Data, to Germany and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to
        that transfer.
      </p>
      <p>
        Pixelchain will take all steps reasonably necessary to ensure that your data is treated securely and in
        accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the security of your data and other personal
        information.
      </p>
      <h2>Disclosure Of Data</h2>
      <h3>Legal Requirements</h3>
      <p>Pixelchain may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Pixelchain</li>
        <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>To protect the personal safety of users of the Service or the public</li>
        <li>To protect against legal liability</li>
      </ul>
      <h2>Security Of Data</h2>
      <p>
        The security of your data is important to us, but remember that no method of transmission over the Internet, or
        method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect
        your Personal Data, we cannot guarantee its absolute security.
      </p>
      <h2>Service Providers</h2>
      <p>
        We may employ third party companies and individuals to facilitate our Service (&quote;Service Providers&quote;),
        to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our
        Service is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform these tasks on our behalf and are
        obligated not to disclose or use it for any other purpose.
      </p>
      <h3>Analytics</h3>
      <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
      <ul>
        <li>Google Analytics</li>
        <li>
          Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google
          uses the data collected to track and monitor the use of our Service. This data is shared with other Google
          services. Google may use the collected data to contextualize and personalize the ads of its own advertising
          network.
        </li>
        <li>
          You can opt-out of having made your activity on the Service available to Google Analytics by installing the
          Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
        </li>
        <li>
          For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
          <a
            className="secondary"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;ust=1537684567937000"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </li>
      </ul>
      <h2>Links To Other Sites</h2>
      <p>
        Our Service may contain links to other sites that are not operated by us. If you click on a third party link,
        you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third
        party sites or services.
      </p>
      <h2>Children&apos;s Privacy</h2>
      <p>Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</p>
      <p>
        We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a
        parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us.
        If we become aware that we have collected Personal Data from children without verification of parental consent,
        we take steps to remove that information from our servers.
      </p>
      <h2>Changes To This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective
        and update the &quot;effective date&quot; at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us:</p>
      <ul>
        <li>By email: info@CryptoMotors.io</li>
      </ul>
    </Center>
  </Privacy>
);

export default withRouter(PrivacyComponent);
