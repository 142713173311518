import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const Terms = styled.div`
  background: #1e2132 none repeat scroll 0 0;
  width: 100%;
  color: #FFFFFF
  font-family: 'Roboto', sans-serif;
`;

const Center = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 50rem;
  p > ol > li {
    margin: 2rem 0;
  }
  p > ol > li > ol > li {
    margin: 1rem 0;
  }
`;

const TermsComponent = () => (
  <Terms>
    <Center>
      <h1>General Terms of Use</h1>
      <p>
        Please read through the following Terms of Use carefully, which apply to every visitor and every user of our
        app/and website. Through the use of the website, you give your implied consent to these Terms of Use.
      </p>
      <h3>I. Scope of applicability; subject matter</h3>
      <p>
        <ol>
          <li>The following General Terms of Use apply to all business relations with our users.</li>
          <li>
            Our Terms of Use apply exclusively. Any deviating, inconsistent or supplementary general terms of use of the
            user shall become an integral part of the contract only if and to the extent that we have expressly approved
            the validity thereof in writing.
          </li>
          <li>
            PixelChain is a decentralized application, an online image editor that is set up on the Ethereum blockchain
            and uses specially developed “smart contracts”. The application enables users to draw and tokenize 32x32px
            images (hereinafter “Crypto-Collectibles”, “PixelChains”, “CryptoArtworks” or “CryptoArt”, technically a
            token in the blockchain environment) that are encoded into the token information string. CryptoMotors UG,
            creator of Pixelchain, makes an app and a website available for this purpose. The user community should also
            be granted access to the platform so that it can take part in the creation of Crypto-Collectibles.
            <br />
            <br />
            We use the network to start the website and to let users create Crypto-Collectibles that are in full
            possession of the creator, being transferred from the smart contract to the creator wallet at the moment of
            creation. There is not a limit of artwork to be ever created.
          </li>
          <li>
            PixelChain platform is free to use to design and draw artworks. In case an user wants to “mint” (process of
            creating a token) their artwork, a 0.008 ETH fee will be charged at the moment of the creation. Also a
            variable network fee will be charged by the ethereum network. Every artwork provided on the platform has to
            be original and after saving or minting it will be encoded into the ERC721 token with its color palette and
            the wallet address of the creator. Once created the artwork cannot be destroyed or taken away from any user.
            <br />
            <br />
            PixelChain app is a digital digital image editor, in other words, it is a tool. Anyone uploading artwork
            accepts full responsibility for what they upload to the ethereum network. These artworks once created can be
            sold by its creator on an external platform called “OpenSea” (https://opensea.io/). If the artist decides to
            mint several times the
          </li>
          <li>
            Entertainment: PixelChain will sporadically organize art challenges and activities with its community.
          </li>
        </ol>
      </p>
      <h3>II. Your account</h3>
      <p>
        <ol>
          <li>A user account must be created to access several (chargeable) services of the website and the app.</li>
          <li>Only fully legally competent persons are permitted to use our website and/or app.</li>
          <li>
            The user undertakes not to provide any false particulars on his identity. Furthermore, the user also
            undertakes to check his particulars on a regular basis in order to ensure they are correct.
          </li>
          <li>
            If you use our services, you are responsible for ensuring the confidentiality of your account, your password
            and for the restriction of access to your computer and your mobile equipment as well as for the security of
            your electronic wallet (“Wallet”). You undertake to take all the measures required to ensure that your
            password remains secret and is safely stored.
          </li>
          <li>
            You undertake to inform us without delay if you find out that a third party has gained knowledge of your
            password or the password is being used without authorisation or this is probable.
          </li>
          <li>You may change your user name and your password subsequently at any time if it would be needed.</li>
          <li>
            You may not use our services in a manner suitable for interrupting or damaging the services or for impairing
            them in any other way. Furthermore, you may not use the services for activities that are fraudulent or
            connected with a criminal offence or illegal activities.
          </li>
          <li>
            We reserve the right to withhold the services from you on the website or to close members’ accounts at our
            discretion. This applies particularly in the event that you violate applicable law, contractual agreements
            or our Terms of Use. This shall have no effect on your rights to the Crypto-Collectibles.
          </li>
          <li>
            Users of the website and/or the app may delete their account at any time. After deletion of the account, you
            will no longer be able to access the service. This shall have no influence on your rights to the
            Crypto-Collectibles.
          </li>
        </ol>
      </p>
      <h3>III. Fees and payment</h3>
      <p>
        <ol>
          <li>
            To conduct transactions on the app and/or on the website, you must first install a browser extension named
            MetaMask (or another cryptocurrency-compatible browser extension). This requires that you have installed a
            web browser compatible for this, such as the Google Chrome web browser. MetaMask is an electronic wallet
            (“Wallet”) that allows you to purchase cryptocurrency directly via the Coinbase platform, save it and
            conduct transactions in cryptocurrency. You can conduct transactions on our website and/or app only via
            MetaMask (or other cryptocurrency-compatible browser extensions). To do so, you must connect your Wallet to
            our website and app.
          </li>
          <li>
            In order to purchase a Crypto-Collectible or additional functions, extensions or modifications, the prices
            shown for the respective products must be paid to CryptoMotors UG.
          </li>
          <li>
            If you conduct transactions on the website and/or app, these transactions will be conducted solely via the
            cryptocurrency network.
          </li>
          <li>
            Cryptocurrencies demand the payment of a transaction fee for transactions that take place in the network.
            You must therefore pay such a transaction fee for each transaction.
          </li>
          <li>
            On the app or website, we offer you a connection to OpenSea, a platform where you can trade your
            Crypto-Collectibles (“Resale”). In the event of a Resale via OpenSea platform, we charge commission of 10%.
            You hereby agree to this commission being forwarded to us directly through the cryptocurrency network as
            part of your payment. We will not collect any commission for transactions that were not conducted via
            OpenSea website and/or app.
          </li>
          <li>All prices already include statutory value-added tax, unless expressly otherwise stated.</li>
        </ol>
      </p>
      <h3>IV. Rights; copyright</h3>
      <p>
        <ol>
          <li>
            The contents, works and the information published and provided on our website and app are subject to German
            copyright and ancillary copyright.
          </li>
          <li>
            CryptoMotors UG is the sole holder of all the rights and property rights to the services and contents
            provided on our website and app by CryptoMotors UG such as the image editor, decoder, brand image, brand
            name and smart contracts.{" "}
          </li>
          <li>
            Users will use Pixelchain to create content that will become their sole responsibility. Nevertheless
            PixelChain has the right to share the content created in its platform and smart contract. Also Pixelchain
            can flag and delete any content that is in violation with our terms and conditions.{" "}
          </li>
          <li>
            Artworks created by the users are encoded in the form of tokens in the ethereum network containing the
            artist wallet address to prove authenticity.
          </li>
          <li>The intellectual property remains the possession of the creator.</li>
          <li>Owning a digital artwork (Pixelchain token or NFT) is analogous to owning a physical one.</li>
          <li>
            Ownership of a digital artwork (Pixelchain token or NFT) does not include intellectual property rights such
            as copyright claims, ability to produce commercially, and create merchandise therefrom, etc.
          </li>
          <li>
            You can create new Crypto-Collectibles on our website and app. Crypto-Collectibles are intangible digital
            assets that exist only on the basis of the proof kept in the cryptocurrency network and are not saved on a
            physical disk. Our website and/or app do not save, send or receive any Crypto-Collectibles themselves. This
            is due to the fact that Crypto-Collectibles exist only on the basis of the proof in the blockchain in the
            cryptocurrency network, specifically in the Ethereum blockchain.
          </li>
          <li>
            If you purchase a Crypto-Collectible, we shall grant you limited, not exclusive, transferable rights to use
            the Crypto-Collectible for the following purposes:
            <ol>
              <li>for personal, non-commercial use;</li>
              <li>
                for the transaction of the rights on marketplaces, provided the marketplace cryptographically checks the
                rights of each Crypto-Collectible purchaser to ensure that only the actual rights holder can display and
                transfer the Crypto-Collectibles. In particular, the Crypto-Collectibles may be transferred on the
                marketplace called OpenSea.
              </li>
              <li>
                For inclusion, involvement or participation in other tools or activities on our website and/or app or on
                a third-provider website or application provided that the website/application cryptographically checks
                the rights of each Crypto-Collectible purchaser to ensure that only the actual rights holder can display
                and use the Crypto-Collectibles.
              </li>
              <li>
                For the modification of the Crypto-Collectibles, provided the modifications can be cryptographically
                checked. In particular, Crypto-Collectibles can be modified through the services provided by us.
              </li>
            </ol>
          </li>
          <li>
            It will be possible to visualise the Crypto-Collectibles on PixelChain platform or that of our partner. Also
            we have developed an open-source “decoder” that users can use to extract the token information to generate
            the image without using our services.
          </li>
          <li>
            The rights granted apply only to the extent to which you continue to be the holder of the rights to the
            respective Crypto-Collectibles. Once you transfer these, your rights lapse.
          </li>
          <li>
            Any kind of reproduction, processing, dissemination, saving and any kind of use beyond the limits of the
            rights requires our prior written approval. In particular, the Crypto-Collectibles may not be:
            <ol>
              <li>used to advertise the products or services of third parties;</li>
              <li>
                used in connection with images, videos or other media that show hatred, intolerance and/or violence or
                infringe third-party rights;
              </li>
              <li>used for commercial purposes if it applies.</li>
            </ol>
          </li>
          <li>
            PixelChain reserves the right of final decision in case of a copyright violation, resulting in the removal
            of the token from OpenSea marketplace, censored of the image in OpenSea website and the removal of the
            rights to create more artworks using PixelChain app.
          </li>
          <li>
            CryptoMotors UG expects and asks that the artworks created in the platform are created exclusively by the
            artist who has tokenized the artwork, nevertheless, CryptoMotors UG is not liable if a user breaks this
            terms and conditions and tokenizes artworks that are not their original creation.
          </li>
        </ol>
      </p>
      <h3>V. Art competitions</h3>
      <p>
        <ol>
          <li>
            The users of our website and/or app can take part in “art competitions” organised by us at irregular
            intervals. In addition to these Terms of Use, the respective conditions of participation apply to the
            competitions.
          </li>
        </ol>
      </p>
      <h3>VI. Liability</h3>
      <p>
        <ol>
          <li>
            PixelChain shall endeavour to offer the service for access without interruptions as far as possible. Even
            when applying the greatest care, it is not possible to prevent downtimes when the web server cannot be
            accessed via the Internet on account of technical or other problems which are beyond the control of
            PixelChain. The user acknowledges that the full availability (100%) of the website cannot be technically
            realised.
          </li>
          <li>
            Unless otherwise stated in our Terms of Use including the following provisions, we shall be liable in the
            event of a breach of contractual and extra-contractual obligations in accordance with legal provisions
          </li>
          <li>
            We shall be liable for damages – regardless of the legal ground – within the limits of fault-based liability
            in case of intent and gross negligence. In case of simple negligence, we shall be liable in accordance with
            legal provisions subject to a milder standard of liability (e.g. for care applied to one’s own matters) only
            <ol>
              <li>for damage arising from loss of life, physical injury or an impairment of health;</li>
              <li>
                for damage arising from a not insignificant breach of an essential contractual obligation (obligation,
                the fulfilment of which renders the proper implementation of the contract possible in the first place
                and on compliance with which the contractual party regularly relies and may rely); in this case our
                liability is, however, restricted to compensation for foreseeable damage that typically arises.
              </li>
              <li>
                The limitations of liability defined under 2. shall also apply to breaches of duty by or in favour of
                persons for whose fault we must assume responsibility in accordance with legal provisions or have
                assumed a guarantee for the condition of the Crypto-Collectibles and for claims of the purchaser based
                on the Product Liability Act.
              </li>
            </ol>
          </li>
          <li>
            As a user you are responsible for the implementation of reasonable measures to safeguard the Wallet, safe or
            another storage mechanism that you use for the purchase, exchange or use of platform-internal elements,
            tokens and/or other objects on the PixelChain platform, currently consisting of an app and a website,
            including all the required private passwords or other access data that are required for access to these
            storage mechanisms. As a platform organiser, PixelChain is not responsible and shall remain not responsible
            for losses entailed in the loss of your access data or in third-party access to your access data.
          </li>
          <li>
            Through the purchase of platform-internal articles and/or the receipt of tokens and the use of the
            PixelChain platform, you declare and guarantee the following:
            <ol>
              <li>
                You have a sufficient understanding of and experience with cryptographic tokens (particularly Ethereum),
                token storage mechanisms (e.g. token Wallets) and blockchain technology in order to understand these
                terms and understand the risks and effects of the acquisition, the procurement and the exchange of
                platform-internal elements on Ethereum.
              </li>
              <li>You have read and understood these terms (including all the annexes, if available).</li>
              <li>
                You have received sufficient information on the PixelChain platform to make an informed decision on the
                purchase, holding or exchange of platform-internal articles and/or tokens.
              </li>
              <li>
                You will not purchase, hold or exchange platform-internal articles and/or tokens for purposes other than
                to take part in the PixelChain platform or that of our partners and the platform functions declared in
                these Terms of Use on the website and/or on the app.
              </li>
              <li>
                Your purchase, your procurement and your exchange of articles and tokens tied to the platform comply
                with applicable laws and regulations in your place of jurisdiction, including but not limited to (i) the
                legal capacity and all other threshold requirements in your jurisdiction for the use and payment of
                Ether and the conclusion of contracts with the platform organiser, (ii) all the foreign currency or
                regulatory restrictions for this purchase and (iii) all the government or other approvals that must be
                obtained.
              </li>
              <li>
                You will comply with all the tax obligations applicable in your jurisdiction which arise from the
                purchase, the procurement or the exchange of cross-platform articles, if necessary.
              </li>
              <li>
                If you purchase, hold or exchange platform-independent articles or tokens in the name of a natural or
                legal person, you are entitled to accept these terms in the name of this natural or legal person and
                this natural or legal person shall be responsible for any infringement of these terms by you or this
                natural or legal person or another employee or representative of this person.
              </li>
              <li>
                You agree that when using the platform there is a risk that digital assets in the form of cryptocurrency
                can be lost, particularly through the loss of private keys, and that the platform organiser is not
                liable to you for any such loss.
              </li>
            </ol>
          </li>
        </ol>
      </p>
      <h3>VII. Third-party providers; third-party applications; fluctuations in value</h3>
      <p>
        <ol>
          <li>
            The website and the app contain what are known as “external links” to other websites, the contents of which
            are beyond our control. For this reason we assume no liability for these contents. The respective provider
            of the linked website is responsible for the contents and the accuracy of the information provided. At the
            time of placing the link, no legal infringements were detected. If such a legal infringement becomes known,
            the link shall be immediately removed.
          </li>
          <li>
            Furthermore, we have no influence on the applications, products or services of third parties or other
            websites such as MetaMask, Coinbase, Google Chrome, cryptocurrency networks which you access to enable use
            of the various functions of the website and/or app. The respective provider itself is responsible for the
            applications. For this reason we assume no liability for these either.
          </li>
          <li>
            The values of blockchain assets are subject to extreme fluctuations. We cannot guarantee that purchasers of
            Crypto-Collectibles will not lose money.
          </li>
        </ol>
      </p>
      <h3>VIII. Indemnification</h3>
      <p>
        You undertake to indemnify CryptoMotors UG from all third-party claims and from all liabilities, payment
        obligations, costs or damage that arise on account of or in connection with
        <ol>
          <li>your violations of the Terms of Use,</li>
          <li>
            your infringements of industrial property rights or other rights of data protection provisions of third
            parties or
          </li>
          <li>
            any misuse of the service by third parties if the misuse was rendered possible by your failing to take
            reasonable measures to protect your user name and password against misuse by third parties.
          </li>
        </ol>
      </p>
      <h3>IX. Data protection</h3>
      <p>
        All personal data are collected, saved, processed and used in accordance with applicable data protection laws
        and our Privacy Statement. To read our Privacy Statement, please click here.
      </p>
      <h3>X. Amendments to the Terms of Use</h3>
      <p>
        We reserve the right to make changes to our services such as the website and the app and amend sets of rules,
        conditions, including these Terms of Use at any time. They are subject to the respective conditions in force at
        the time when you use our services.
      </p>
      <h3>XI. Miscellaneous</h3>
      <p>
        You undertake to indemnify CryptoMotors UG from all third-party claims and from all liabilities, payment
        obligations, costs or damage that arise on account of or in connection with
        <ol>
          <li>
            This Agreement and all the legal relationships of the parties are governed by the law of the Federal
            Republic of Germany to the exclusion of the UN Sales Convention (CISG).
          </li>
          <li>
            Should individual provisions of these Terms of Use be invalid, this shall not affect the validity of the
            remaining provisions. Invalid provisions shall be replaced by those that most closely approximate the
            meaning intended by the invalid provision. The same applies in the event of the occurrence of any gaps which
            need to be filled.
          </li>
          <li>
            If a user is a merchant, the place of jurisdiction for all disputes arising under contractual relationships
            between the user and the company is the registered office of the company.
          </li>
          <li>
            CryptoMotors UG will not take part in dispute settlement procedures before a consumer arbitration board to
            settle disputes with consumers.
          </li>
        </ol>
      </p>
    </Center>
  </Terms>
);

export default withRouter(TermsComponent);
