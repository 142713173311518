import styled from "styled-components";

const TextButton = styled.button`
  font-family: "silkscreen";
  font-size: 2em;
  color: white;
  background: rgb(125, 141, 169);
  cursor: pointer;
  border: none;
  padding: 5px;
`;

export default TextButton;
