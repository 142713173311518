import React from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import MetamaskEnableModal from "components/MetamaskEnableModal";
import MetamaskSupportModal from "components/MetamaskSupportModal";
import SavingModal from "components/SavingModal";
import SavedModal from "components/SavedModal";
import ForceMainnetModal from "components/ForceMainnetModal";
import Modal from "components/Modal";

import { v1Contract, v2Contract } from "./contracts";

export default function withPixelChainContract(WrappedComponent) {
  return class extends React.Component {
    state = {
      showSavedModal: false,
      showSavingModal: false,
      showMetamaskSupportModal: false,
      showEnableMetamaskModal: false,
      showForceMainnetModal: false,
      showWaitingUserInputModal: false,
      tokenId: -1,
      transactionHash: null,
      contract: null,
      metamaskEnabled: false,
      web3: null,
      provider: null,
      walletAddress: null,
    };

    componentDidMount = async () => {
      await this.setupWeb3();
    };

    setupWeb3 = async () => {
      let provider = null;
      let web3 = null;

      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
        provider = window.ethereum;
        this.setState({ web3, provider: window.ethereum });
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
        provider = window.web3.currentProvider;
        this.setState({ web3, provider: window.web3.currentProvider });
      } else {
        const walletConnectProvider = new WalletConnectProvider({
          chainId: process.env.REACT_APP_CHAIN_ID,
          infuraId: process.env.REACT_APP_INFURA_ID,
        });

        web3 = new Web3(walletConnectProvider);
        provider = walletConnectProvider;
        this.setState({ web3, provider: walletConnectProvider });
      }

      if (provider.on) {
        provider.on("disconnect", () => {
          this.setState({ walletAddress: null });
        });

        provider.on("accountsChanged", (accounts) => {
          if (!accounts[0]) {
            this.setState({ walletAddress: null });
            return;
          }

          this.setState({ walletAddress: accounts[0] });
        });

        provider.on("networkChanged", (networkId) => {
          this.setState({ networkId });
        });

        provider.on("chainChanged", (networkId) => {
          this.setState({ networkId });
        });
      }

      if (provider.wc && provider.wc._connected) {
        provider.enable();
      }

      const account = await web3.eth.getAccounts();

      if (account[0]) {
        this.setState({ walletAddress: account[0] });
      }

      const networkId = await web3.eth.net.getId();
      this.setState({ networkId });
    };

    connectWallet = () => {
      if (this.state.walletAddress !== null) {
        return Promise.resolve();
      }

      if (this.state.provider.enable) {
        return this.state.provider.enable().catch(console.log);
      } else {
        return this.state.provider.send("eth_requestAccounts").catch(console.log);
      }
    };

    disconnectWallet = async () => {
      if (this.state.walletAddress === null) {
        return;
      }

      if (this.state.provider.disconnect) {
        this.state.provider.disconnect();
        return;
      }

      if (this.state.provider.close) {
        this.state.provider.close();
        return;
      }

      alert("This wallet can only be disconnected through your wallet provider");
    };

    createToken = (grid, palette, name) => {
      if (!name || name === "") {
        alert("You should put a name to your masterpiece! ;)");
        return;
      }

      return this.connectWallet()
        .then(() => this.callContractCreate(grid, palette, name))
        .catch((error) => {
          if (error === "metamask_not_found") {
            this.showMetamaskSupportModal();
          } else if (error === "metamask_not_enabled") {
            this.showEnableMetamaskModal();
          } else if (error === "mainnet_not_selected") {
            this.showForceMainnetModal();
          } else {
            console.log(error);
          }
        });
    };

    retrieveToken = (tokenId, version = 2) => {
      return this.connectWallet()
        .then(() => this.callContractRetrieve(tokenId, version))
        .catch((error) => {
          if (error === "metamask_not_found") {
            this.showMetamaskSupportModal();
          } else if (error === "metamask_not_enabled") {
            this.showEnableMetamaskModal();
          } else if (error === "mainnet_not_selected") {
            this.showForceMainnetModal();
          } else {
            console.log(error);
          }
        });
    };

    callContractCreate = async (grid, palette, name) => {
      this.showWaitingUserInputModal();

      const callMethod = await v2Contract.createToken(this.state.web3, name, grid, palette);

      callMethod()
        .on("transactionHash", (hash) => {
          this.hideWaitingUserInputModal();
          this.showSavingModal(hash);
        })
        .on("receipt", (receipt) => {
          console.log("RECEIPT", receipt);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 0) {
            this.hideSavingModal();
            this.showSavedModal(receipt.events.PixelChainCreated.returnValues.tokenId);
          }

          console.log("CONFIRMED", confirmationNumber, receipt);
        })
        .on("error", (error, receipt) => {
          this.hideWaitingUserInputModal();
          console.log("ERROR", error, receipt);
        });
    };

    callContractRetrieve = async (tokenId, version) => {
      switch (version) {
        case 1:
          return v1Contract.retrieveToken(this.state.web3, tokenId);
        case 2:
        default:
          return v2Contract.retrieveToken(this.state.web3, tokenId);
      }
    };

    hideSavingModal = () => this.setState({ showSavingModal: false });

    showSavingModal = (hash) => this.setState({ showSavingModal: true, transactionHash: hash });

    showSavedModal = (tokenId) => this.setState({ showSavedModal: true, tokenId });

    hideSavedModal = () => this.setState({ showSavedModal: false });

    showMetamaskSupportModal = () => this.setState({ showMetamaskSupportModal: true });

    showEnableMetamaskModal = () => this.setState({ showEnableMetamaskModal: true });

    hideEnableMetamaskModal = () => this.setState({ showEnableMetamaskModal: false });

    showForceMainnetModal = () => this.setState({ showForceMainnetModal: true });

    hideForceMainnetModal = () => this.setState({ showForceMainnetModal: false });

    showWaitingUserInputModal = () => this.setState({ showWaitingUserInputModal: true });

    hideWaitingUserInputModal = () => this.setState({ showWaitingUserInputModal: false });

    render() {
      return (
        <>
          {this.state.showWaitingUserInputModal && <Modal title="Waiting for transaction approval"></Modal>}
          {this.state.showEnableMetamaskModal && <MetamaskEnableModal onCloseClick={this.hideEnableMetamaskModal} />}
          {this.state.showMetamaskSupportModal && <MetamaskSupportModal />}
          {this.state.showForceMainnetModal && <ForceMainnetModal />}
          {this.state.showSavingModal && <SavingModal transactionHash={this.state.transactionHash} />}
          {this.state.showSavedModal && (
            <SavedModal
              onCloseClick={this.hideSavedModal}
              tokenId={this.state.tokenId}
              transactionHash={this.state.transactionHash}
            />
          )}
          <WrappedComponent
            {...this.props}
            web3={this.state.web3}
            walletAddress={this.state.walletAddress}
            connectWallet={this.connectWallet}
            disconnectWallet={this.disconnectWallet}
            createToken={this.createToken}
            retrieveToken={this.retrieveToken}
          />
        </>
      );
    }
  };
}
