import zlib from "zlib";

function convertGridToHexStringMint(grid, colorQuantity) {
  return grid
    .map((row) =>
      row
        .map((number) => {
          return colorQuantity <= 16 ? number.toString(16) : number.toString(16).padStart(2, "0");
        })
        .join("")
    )
    .join("");
}

function convertPaletteToHexString(arr) {
  return arr.map((color) => color.slice(1)).join("");
}

const encode = (grid, palette) => {
  const paletteData = convertPaletteToHexString(palette);
  const gridData = convertGridToHexStringMint(grid, paletteData.length / 6);

  const data = zlib.deflateSync(gridData, {
    level: 9,
    windowBits: 8,
    memLevel: 9,
  });

  const paletteCompressed = zlib.deflateSync(paletteData, {
    level: 9,
    windowBits: 8,
    memLevel: 9,
  });

  return { data, palette: paletteCompressed };
};

export default encode;
