export function convertPaletteToHexString(arr) {
  return arr.map((color) => color.slice(1)).join("");
}

export function convertHexStringToPalette(str, size = 16) {
  const colorsString = str.slice(2);
  const palette = [];
  for (let i = 0; i < size; i++) {
    palette.push("#" + colorsString.substr(6 * i, 6));
  }

  return palette;
}

export function convertHexStringToGrid(str, size) {
  const grid = Array(size)
    .fill([])
    .map(() => Array(size).fill(255));
  const hexStr = str.slice(2);
  for (let i = 0; i < hexStr.length; i += 2) {
    let row = Math.floor(i / 2 / size);
    let column = Math.ceil((i / 2) % size);
    if (!grid[row]) {
      grid[row] = [];
    }
    grid[row][column] = parseInt(hexStr.slice(i, i + 2), 16);
  }

  return grid;
}

export function convertGridToHexString(grid) {
  return grid
    .map((row) =>
      row
        .map((number) => {
          if (!number) {
            number = 0;
          }

          let hex = number.toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        })
        .join("")
    )
    .join("");
}

export function convertGridToHexStringMint(grid, colorQuantity) {
  return grid
    .map((row) =>
      row
        .map((number) => {
          return colorQuantity <= 16 ? number.toString(16) : number.toString(16).padStart(2, "0");
        })
        .join("")
    )
    .join("");
}
