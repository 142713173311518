import React from "react";
import Modal from "../Modal";
import ReactLoading from "react-loading";
import { TRANSACTION_BASE_URL } from "../../utils/constants";

const SavingModal = ({ transactionHash }) => (
  <Modal title="Saving...">
    <p>Your token is being created, please wait.</p>
    <p>
      <ReactLoading type="cubes" color="white" />
    </p>
    <p>Also, you can check your transaction in Etherscan following the link below</p>
    <p>
      <a
        style={{ color: "#7d8da9", wordBreak: "break-all" }}
        target="_blank"
        rel="noopener noreferrer"
        href={TRANSACTION_BASE_URL + transactionHash}
      >
        {transactionHash}
      </a>
    </p>
  </Modal>
);

export default SavingModal;
