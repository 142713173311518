import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import Logo from "./metamask.png";
import A from "../A";
import TextButton from "../TextButton";

const Image = styled.img`
  height: 100px;
  width: 100px;
`;

const MetamaskEnableModal = ({ onCloseClick }) => (
  <Modal title="Connect your wallet">
    <Image src={Logo} />
    <p>Please connect your Metamask wallet in order to use PixelChain.</p>
    <p>If you are using another client please let us know on Discord so we can integrate it as soon as possible.</p>
    <p>
      <A href="https://discord.gg/PMkRWFX" target="_blank">
        Join our discord!
      </A>
    </p>
    <TextButton onClick={onCloseClick}>Close</TextButton>
  </Modal>
);

export default MetamaskEnableModal;
