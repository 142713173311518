const { SMALL_GRID } = require("utils/grid");

function convertHexStringToPalette(str, size = 16) {
  const colorsString = str.slice(2);
  const palette = [];
  for (let i = 0; i < size; i++) {
    palette.push("#" + colorsString.substr(6 * i, 6));
  }

  return palette;
}

function convertHexStringToGrid(str, size) {
  const grid = Array(size)
    .fill([])
    .map(() => Array(size).fill(255));

  const hexStr = str.slice(2);

  for (let i = 0; i < hexStr.length; i += 2) {
    let row = Math.floor(i / 2 / size);
    let column = Math.ceil((i / 2) % size);

    if (!grid[row]) {
      grid[row] = [];
    }

    grid[row][column] = parseInt(hexStr.slice(i, i + 2), 16);
  }

  return grid;
}

const decode = (data, palette) => {
  const grid = convertHexStringToGrid(data, SMALL_GRID);
  const paletteArray = convertHexStringToPalette(palette);

  return {
    palette: paletteArray,
    grid,
    rawPixels: data,
    rawPalette: palette,
  };
};

export default decode;
