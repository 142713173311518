import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import Logo from "./metamask.png";
import A from "../A";

const Image = styled.img`
  height: 100px;
  width: 100px;
`;

const MetamaskSupportModal = () => (
  <Modal title="Get metamask">
    <Image src={Logo} />
    <p>We currently only support integration with MetaMask.</p>
    <p>If you are using another client please let us know on Discord so we can integrate it as soon as possible.</p>
    <p>
      <A href="https://discord.gg/PMkRWFX" target="_blank">
        Join our discord!
      </A>
    </p>
  </Modal>
);

export default MetamaskSupportModal;
