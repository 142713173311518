import React from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #393c53 none repeat scroll 0 0;
  overflow-x: hidden;
`;

const TabName = styled.div`
  font-family: "Poppins", sans-serif;
  width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseTabButton = styled.div`
  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: grey;
  cursor: pointer;
  background: #212439 none repeat scroll 0 0;
`;

export const Tab = ({ id, name, active, closable, quantity, onTabChange, onTabClose }) => {
  return (
    <TabContainer
      style={{ width: quantity === 1 ? "auto" : `calc(95% / ${quantity})` }}
      onClick={() => onTabChange(id)}
    >
      <TabName style={{ color: active ? "white" : "grey" }}>{name}</TabName>
      {closable && <CloseTabButton onClick={() => onTabClose(id)}>x</CloseTabButton>}
    </TabContainer>
  );
};

export const TabsBar = styled.div`
  width: 100%;
  height: 50px;
  background: #212439 none repeat scroll 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CreateTabButtonContainer = styled.div`
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: grey;
  cursor: pointer;
  background: #393c53 none repeat scroll 0 0;
  margin-right: 5px;
`;

export const CreateTabButton = ({ onCreateTab }) => (
  <CreateTabButtonContainer onClick={onCreateTab}>+</CreateTabButtonContainer>
);
