import zlib from "zlib";

const inflate = (deflated) => zlib.inflateSync(Buffer.from(deflated.replace("0x", ""), "hex")).toString();

const decodeDataToArray = (encodedData) => {
  if (encodedData.length !== 8192 && encodedData.length !== 2048) {
    return encodedData.match(/.{1,1}/g);
  }

  return encodedData.match(/.{1,2}/g);
};

const decodePaletteToArrayOfColors = (encodedPalette) => encodedPalette.match(/.{1,6}/g).map((color) => "#" + color);

const decode = (data, palette) => {
  const rawPixels = inflate(data);
  const rawPalette = inflate(palette);
  const paletteArray = decodePaletteToArrayOfColors(rawPalette);
  const pixelsArray = decodeDataToArray(rawPixels);

  const size = pixelsArray.length === 1024 ? 32 : 64;
  const grid = [];

  pixelsArray.forEach((pixel, index) => {
    const row = Math.floor(index / size);
    if (!grid[row]) {
      grid[row] = [];
    }

    grid[row].push(parseInt(pixel, 16));
  });

  const pxc = {
    rawPixels: rawPixels,
    rawPalette: rawPalette,
    palette: paletteArray,
    grid: grid,
  };

  return pxc;
};

export default decode;
