import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../IconButton";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 425px) {
    flex-wrap: nowrap;
    width: auto;
    padding-left: 380px;
  }
`;

const Picker = styled.div`
  background: white;
  width: 7vmin;
  height: 5vmin;
  position: absolute;
  top: 0;
  left: -40px;
  margin: 5px;
  border-radius: 10%;
  z-index: 0;
  padding-left: 5px;
  display: none;

  @media screen and (max-width: 768px) {
    left: -35px;
  }
  @media screen and (max-width: 425px) {
    left: -30px;
  }
`;

const ColorContainer = styled.div`
  position: relative;
  width: 5vmin;
  height: 5vmin;
  margin: 15px;

  :hover {
    z-index: 1;

    div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    div:nth-child(2) {
      border: 5px solid white;
    }
  }
`;

const Color = styled.div`
  position: absolute;
  z-index: 1;
  background: ${({ color }) => color};
  width: 5vmin;
  height: 5vmin;
  border-radius: 50%;
  cursor: pointer;
  border: ${({ selected, color }) => (selected ? "5px solid white" : `5px solid ${color}`)};

  /* @media screen and (max-width: 691px) {
        width: 20px;
        height: 20px;
    }

    @media screen and (max-width: 791px) {
        width: 30px;
        height: 30px;
    }

    @media screen and (max-width: 991px) {
        width: 40px;
        height: 40px;
    } */
`;

export default class ColorPicker extends React.Component {
  onColorPicked = (color, id) => this.props.onChange({ hex: color, id });

  render() {
    return (
      <Container className={this.props.className}>
        {this.props.palette.map((color, id) => (
          <ColorContainer
            key={id}
            className={`${this.props.paletteSize === 32 ? "max-size-palette" : "min-size-pallete"}`}
          >
            <Picker className="picker">
              <IconButton onClick={() => this.props.onColorPicker(color, id)}>
                <FontAwesomeIcon icon={faEyeDropper} size="lg" />
              </IconButton>
            </Picker>
            <Color
              className="color"
              key={id}
              onClick={() => this.onColorPicked(color, id)}
              selected={this.props.color.hex === color}
              color={color}
            />
          </ColorContainer>
        ))}
      </Container>
    );
  }
}
