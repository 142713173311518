import React from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import IconButton from "../IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  position: absolute;
  padding: 10px;
  background: #212439 none repeat scroll 0 0;
  box-shadow: 0px 0px 50px black;
  left: 50%;
  z-index: 2;
`;

const Handle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none 0px 0px repeat scroll rgb(57, 60, 83);
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
  cursor: move;
`;

const ColorPickerModal = ({ color, onChange, onClose }) => (
  <Draggable handle=".handle" bounds="body">
    <Container>
      <Handle className="handle">
        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faBars} size="1x" />
        <IconButton onClick={onClose} onTouchEnd={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </IconButton>
      </Handle>
      <SketchPicker color={color} onChange={onChange} disableAlpha />
    </Container>
  </Draggable>
);

export default ColorPickerModal;
