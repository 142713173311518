const tools = {
  PENCIL: 0,
  BUCKET: 1,
  LINE: 2,
  CIRCLE: 3,
  SQUARE: 4,
  MIRROR: 5,
};

export const shortcuts = {
  normal: {
    KeyB: tools.PENCIL,
    KeyG: tools.BUCKET,
    KeyV: tools.MIRROR,
  },
  shift: {},
  ctrl: {},
};

export default tools;
