import React from "react";
import Modal from "../Modal";
import TextButton from "../TextButton";
import { TRANSACTION_BASE_URL } from "../../utils/constants";

const SavedModal = ({ tokenId, transactionHash, onCloseClick }) => (
  <Modal title="Success!">
    <p>You have succesfully created your token!</p>
    <p>You can check the result in our decoder following link below</p>
    <p>
      <a style={{ color: "#7d8da9" }} target="_blank" rel="noopener noreferrer" href={`/decoder?id=${tokenId}`}>
        View in decoder
      </a>
    </p>
    <p>And here is the transaction on Etherscan</p>
    <p>
      <a
        style={{ color: "#7d8da9", wordBreak: "break-all" }}
        target="_blank"
        rel="noopener noreferrer"
        href={TRANSACTION_BASE_URL + transactionHash}
      >
        {transactionHash}
      </a>
    </p>
    <TextButton onClick={onCloseClick}>Close</TextButton>
  </Modal>
);

export default SavedModal;
