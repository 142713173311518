import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import Logo from "./metamask.png";

const Image = styled.img`
  height: 100px;
  width: 100px;
`;

const ForceMainnetModal = () => (
  <Modal title="Switch to Main network">
    <Image src={Logo} />
    <p>To access PixelChain, please switch to the main Ethereum network.</p>
  </Modal>
);

export default ForceMainnetModal;
